import Image from "next/image";
import Link from "next/link";
import {useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";
import Tag from "./Tag";
import {useRouter} from "next/router";
import localized from "../locale/locales";

function ConceptCardWrapper({available, handleMouseEnter, handleMouseOut, className = '', children, ...rest}) {
    return (
        <motion.div className={`will-change-transform rounded-lg overflow-hidden shadow-lg ${className}`}
                    onMouseOver={handleMouseEnter}
                    onMouseOut={handleMouseOut}
                    initial={{y: 0}}
                    whileHover={{y: available ? '-0.5rem' : 0}}
                    {...rest}
        >
            {children}
        </motion.div>
    )
}

function ConceptCardLinkWrapper({link, children, ...rest}) {
    return (
        <Link href={link}
              passHref
              className={`relative rounded-lg flex flex-col items-stretch text-center`}
              {...rest}
        >
            {children}
        </Link>
    )
}

function ConceptCardContent({available, video, image, imageAlt, title, active}) {
    const {locale} = useRouter();
    const {common: lang} = localized[locale];

    const [hiddenThumb, setHiddenThumb] = useState(false);
    const refVideo = useRef();

    useEffect(() => {
        if (refVideo.current) {
            active ? refVideo.current.play() : refVideo.current.pause();
        }

        setHiddenThumb(active);
    }, [active]);

    useEffect(() => {
        if (refVideo.current) {
            const source = refVideo.current.querySelector('source');
            source.src = source.dataset.src;
            refVideo.current.load();
        }
    }, []);

    return (
        <>
            {/* Opacity switch flick fix */}
            <div className="absolute top-0 left-0 w-full aspect-square bg-black"/>
            {video && (
                <video
                    className={`absolute top-0 left-0 w-full aspect-square transition-opacity duration-300 ${hiddenThumb ? 'opacity-100' : 'opacity-0'}`}
                    loop={true}
                    playsInline={true}
                    muted={true}
                    ref={refVideo}
                >
                    <source data-src={video} type="video/mp4"/>
                </video>
            )}

            {image && (
                <div
                    className={`relative aspect-square w-full transition-opacity duration-300 ${hiddenThumb ? 'opacity-0' : 'opacity-100'}`}>
                    <Image src={image}
                           alt={imageAlt}
                           className="w-full h-full object-cover"
                    />
                    {!available && (
                        <div className="absolute bottom-6 left-1/2 -translate-x-1/2">
                            <Tag theme="ghost">{lang.comingSoon}</Tag>
                        </div>
                    )}
                </div>
            )}


            <div className="py-6 bg-white">
                <p className="text-2xl">{title}</p>
            </div>
        </>
    )
}

export default function ConceptCard({
                                        image,
                                        video,
                                        active,
                                        onMouseEnter,
                                        onMouseOut,
                                        title,
                                        link,
                                        available,
                                        imageAlt = '',
                                        className = '',
                                        ...rest
                                    }) {

    const handleMouseEnter = () => {
        if (onMouseEnter) onMouseEnter();
    }

    const handleMouseOut = () => {
        if (onMouseOut) onMouseOut();
    }

    if (!available) {
        return (
            <ConceptCardWrapper
                className={`relative rounded-lg text-black/40 flex flex-col items-stretch text-center ${className}`}
                available={available}
                {...rest}
            >
                <ConceptCardContent
                    video={video}
                    image={image}
                    imageAlt={imageAlt}
                    title={title}
                    active={active}
                    available={available}
                />
            </ConceptCardWrapper>
        )
    }

    return (
        <ConceptCardWrapper
            available={available}
            handleMouseEnter={handleMouseEnter}
            handleMouseOut={handleMouseOut}
            className={className}
            {...rest}
        >
            <ConceptCardLinkWrapper link={link}>
                <ConceptCardContent
                    video={video}
                    image={image}
                    imageAlt={imageAlt}
                    title={title}
                    active={active}
                    available={available}
                />
            </ConceptCardLinkWrapper>
        </ConceptCardWrapper>
    )
}
